<template lang="pug">
div(:style="[smallPriveiew ? {'background': '#E2F0E8'} : {}]")
    h2.font-24.text-primary.text-weight-bold.q-pt-sm.q-mt-md.q-mb-sm.q-ml-md.q-pa-none.line-height-24(v-if="label") {{ label }}
    q-scroll-area.full-width(
        horizontal
        :thumb-style="{display: 'none'}"
        :style="[smallPriveiew ? 'height: 163px;' : 'height: 365px;']")
        .row.no-wrap(v-if="!smallPriveiew")
            q-skeleton.q-ml-md(
                style="width: 148px; height: 294px"
                v-if="!sliderReady"
                v-for="(item, index) in slides"
            )
            preview-mobile.prevew-mobile.q-mt-sm(
                v-show="sliderReady"
                :style="basket ? 'margin-left: 0 !important'  : ''"
                v-for="(item, index) in slides"
                :data="item"
                :basket="basket"
                :variant="variant"
                :pickupVariant="pickupVariant"
                :recommendationId="recommendationId"
                :sliderProductDay="sliderProductDay"
                @cleanRecom="$emit('cleanRecom')"
                @setSlideHeight="setSlideHeight"
                @showAnalogsList="showAnalogsList"
                @sliderReady="sliderRendered"
                @reorderProduct="reorderProduct"
            )
        .row.no-wrap.q-py-md.q-pl-md(v-else)
            preview-mobile-small(
                v-for="(item, index) in slides"
                :data="item"
                :basket="basket"
                :variant="variant"
                :pickupVariant="pickupVariant"
                @reorderProduct="reorderProduct"
                :productCardPreview="productCardPreview"
                :view-cart="viewCart"
                :sliderProductDay="sliderProductDay"
            )
    analogs-mobile(
        :showAnalogsDialog="showAnalogsDialog"
        @closeAnalogsDialog="closeAnalogsDialog"
    )
</template>

<script>
import { ref, computed } from 'vue';
import PreviewMobile from 'components/Preview/PreviewMobile.vue';
import AnalogsMobile from 'src/components/Analogs/AnalogsMobile.vue';

import PreviewMobileSmall from '../Preview/PreviewMobileSmall.vue';

export default {
    name: 'ProductSliderMobile',
    props: {
        label: {
            type: String,
            default: null,
        },
        slides: {
            type: Array,
            default: () => null,
            required: true,
        },
        subtitle: {
            type: Boolean,
            default: () => true,
        },
        manufacture: {
            type: Boolean,
            default: () => true,
        },
        count: {
            type: Boolean,
            default: () => true,
        },
        basket: {
            type: Boolean,
            default: () => false,
        },
        variant: {
            type: String,
        },
        pickupVariant: {
            type: String,
        },
        recommendationId: {
            type: String,
        },
        smallPriveiew: {
            type: Boolean,
            default: () => false,
        },
        reorder: {
            type: Boolean,
            default: () => false,
        },
        reorderedProduct: {
            type: Object,
        },
        productCardPreview: {
            type: Boolean,
            default: () => false,
        },
        viewCart: {
            type: Boolean,
            default: () => false,
        },
        sliderProductDay: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        PreviewMobile,
        AnalogsMobile,
        PreviewMobileSmall,
    },
    setup(props, { emit }) {
        const heightSlide = ref(15);
        const showAnalogsDialog = ref(false);

        // установка высоты слайдера
        const setSlideHeight = (e) => {
            heightSlide.value = e;
        };

        // стили для превью
        const styles = computed(() => {
            return 'width: 152px !important;';
        });

        const showAnalogsList = () => {
            showAnalogsDialog.value = true;
        };

        const closeAnalogsDialog = () => {
            showAnalogsDialog.value = false;
        };

        const reorderProduct = (product) => {
            emit('reorderProduct', { product, reorderedProduct: props.reorderedProduct });

        };
        const sliderReady = ref(false);
        const sliderRendered = () => {
            sliderReady.value = true;
        };

        return {
            sliderRendered,
            sliderReady,
            styles,
            heightSlide,
            setSlideHeight,
            showAnalogsDialog,
            showAnalogsList,
            closeAnalogsDialog,
            reorderProduct,
        };
    },
};
</script>

<style lang="scss">
.prevew-mobile:first-child {
    margin-left: 16px;
}
</style>
