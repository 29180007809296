<template lang="pug">
.column.no-wrap.relative-position.q-pa-sm.bg-secondary.b-radius-16.preview.q-mr-md(v-if="!productCardPreview" )
    .absolute-top-right.row.items-center.justify-center.q-pa-sm.preview-mobile__icons(
        @click.prevent.stop="updateFavourite")
        .flex.column.items-end
            q-icon.q-mt-xs.q-mr-xs(
                name="etabl:heart"
                size="20px"
                :color="isFavourite ? 'negative' : 'grey-7'")
            //q-icon.q-mt-xs.q-mr-xs(
            //    v-if="data.isMarker"
            //    name="etabl:side-sale"
            //    size="22px"
            //    color="negative")
            //.b-radius-16.border-negative.font-12.text-negative.text-weight-medium.q-px-xs.q-mt-sm(v-if="data.discount && recommendationId")
            //    | {{ data.discount }}
    .absolute-top-left.justify-center.relative-position.row.q-pa-sm.preview-mobile__icons.z-100
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.isPrescription && !data.isErp"
            name="img:rx.svg"
            size="20px"
            color="negative"
            @click.prevent.stop="")
            base-mobile-tooltip(title="По рецепту")
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.isPrescription && data.isErp"
            name="img:rx_e.svg"
            size="20px"
            color="negative"
            @click.prevent.stop="")
            base-mobile-tooltip(title="По электронному рецепту")
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.cold"
            name="etabl:frost"
            size="20px"
            color="light-blue"
            @click.prevent.stop="")
            base-mobile-tooltip(title="Особые условия хранения")
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.truCode"
            name="img:fss.svg"
            size="20px"
            @click.prevent.stop="")
            base-mobile-tooltip(:title="fssTitle")
    .row
        .col-3.flex.items-center
            q-img.full-height(
                no-spinner
                fit="contain"
                ref="slideImg"
                :src="data.previewImage"
                :title="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`"
                :alt="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`")
        .col-9.q-pl-sm
            .text-primary.text-weight-regular.line-height-20.text-ellipsis.font-14.q-pb-xs
                span.block#name(style="white-space: pre-line;")
                    | {{formattedName(`${data.name} ${data.subtitleShort}`)}}
            //.text-primary.text-weight-medium.line-height-20.q-mt-xs.text-ellipsis
            //    | {{ data.subtitleShort }}
            .text-grey-9.text-weight-regular.line-height-20.text-ellipsis.font-14
                | {{data.manufacture}}
            div(v-if="notAvailable").q-mb-sm.q-pa-none.q-mt-sm.q-pr-lg
                p.q-ma-none.text-weight-medium.text-grey-8 нет в наличии
            .row.q-mt-xs.justify-between.items-end.no-wrap(v-else style="min-height: 40px")
                //.text-positive.text-weight-medium.line-height-20
                //    | {{ data.price }} ₽
                .row.items-center.no-wrap
                    .column.text-positive.text-weight-medium.line-height-20
                        template(v-if="data.isMarker")
                            .row.no-wrap.items-center(v-if="data.priceCrossed && data.priceCrossed !== data.price")
                                span.font-12.card__price-new.text-grey-9.text-weight-medium(
                                    v-if="(data.priceCrossed !== data.price) && data.priceCrossed"
                                    style="text-decoration: line-through;")
                                    span(v-if="!userAddress?.address_id" ) от&nbsp;
                                    | {{ new Intl.NumberFormat('ru-RU').format(data.priceCrossed) }}
                                    span &nbsp₽
                                .sale.q-ml-xs.b-radius-16.outline-negative.flex.items-center(v-if="salePercent > 0" style="padding: 2px 4px")
                                    .font-12.line-height-16.text-negative.text-weight-medium
                                        | {{ salePercent }}%
                            span.text-weight-bold.font-14.card__price-old
                                span(v-if="!userAddress?.address_id" ) от&nbsp;
                                | {{ new Intl.NumberFormat('ru-RU').format(data.price) }}
                                span &nbsp₽
                        template(v-else)
                            span.text-weight-bold.font-14.card__price-old(v-if="!!data.price" )
                                span(v-if="!userAddress?.address_id" ) от&nbsp;
                                |  {{ new Intl.NumberFormat('ru-RU').format(data.price) }}
                                span &nbsp₽
                base-btn(
                    v-if="!basket || !data.inBasket"
                    width="90"
                    height="24"
                    color="blue-12"
                    label="Заменить"
                    font_size="12"
                    noPadding
                    :loading="loading"
                    loading_color="white"
                    @click.prevent.stop="reorderProduct(data)"
                )
                .row.justify-end.product__counter.no-wrap(v-if="data.inBasket || inCart ")
                    q-btn.justify-center.items-center.product__button.product__button-min.cursor-pointer.font-16.text-positive(
                        unelevated
                        rounded
                        @click.stop.prevent="setAmount(0)") &ndash;
                    .row.justify-center.items-center.product__count.font-16.text-weight-medium.text-positive(v-if="!loading" ) {{ amount }}
                    .row.justify-center.items-center.product__count.font-16.text-weight-medium.text-positive(v-if="loading" )
                        q-spinner(
                            :thickness="5"
                            :color="'positive'")
                    q-btn.justify-center.items-center.product__button.product__button-plus.cursor-pointer.font-16.text-positive(
                        v-if="!preventAmoutUp"
                        unelevated
                        rounded
                        @click.stop.prevent="setAmount(1)") +
                    q-btn.justify-center.items-center.product__button.product__button__disable.cursor-pointer.font-16(
                        v-else
                        unelevated
                        rounded
                        @click.stop.prevent="") +
                        base-tooltip(
                            style="max-width: 300px;"
                            title="Количество ограничено")
                //base-btn(
                //    v-if="basket && data.inBasket"
                //    width="90"
                //    height="24"
                //    color="grey-9"
                //    label="В корзине"
                //    font_size="12"
                //    noPadding
                //    @click.prevent.stop=""
                //)

// Слайдер из карточки товара
.column.no-wrap.relative-position.q-py-sm.q-px-sm.bg-secondary.b-radius-16.preview.q-mr-md(v-if="productCardPreview" @click="goTo" )
    .absolute-top-right.row.items-center.justify-center.q-pa-sm.preview-mobile__icons(
        @click.prevent.stop="updateFavourite")
        .flex.column.items-end(v-if="viewCart" )
            q-icon.q-mt-xs.q-mr-xs(
                name="etabl:heart"
                size="20px"
                :color="isFavourite ? 'negative' : 'grey-7'")
            //q-icon.q-mt-xs.q-mr-xs(
            //    v-if="data.isMarker"
            //    name="etabl:side-sale"
            //    size="22px"
            //    color="negative")
            //.b-radius-16.border-negative.font-12.text-negative.text-weight-medium.q-px-xs.q-mt-sm(v-if="data.discount && recommendationId")
            //    | {{ data.discount }}
    .absolute-top-left.justify-center.relative-position.row.q-pa-sm.preview-mobile__icons.z-100
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.isPrescription && !data.isErp"
            name="img:rx.svg"
            size="20px"
            color="negative"
            @click.prevent.stop="")
            base-mobile-tooltip(title="По рецепту")
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.isPrescription && data.isErp"
            name="img:rx_e.svg"
            size="20px"
            color="negative"
            @click.prevent.stop="")
            base-mobile-tooltip(title="По электронному рецепту")
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.cold"
            name="etabl:frost"
            size="20px"
            color="light-blue"
            @click.prevent.stop="")
            base-mobile-tooltip(title="Особые условия хранения")
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.truCode"
            name="img:fss.svg"
            size="20px"
            @click.prevent.stop="")
            base-mobile-tooltip(:title="fssTitle")
    .row
        .col-3.flex.items-center
            q-img.full-height(
                no-spinner
                fit="contain"
                ref="slideImg"
                :src="data.previewImage"
                :title="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`"
                :alt="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`")
        .col-9.q-pl-sm
            .text-primary.text-weight-regular.line-height-20.text-ellipsis.font-14.q-pb-xs
                span.block#name(style="white-space: pre-line;")
                    | {{formattedName(`${data.name} ${data.subtitleShort}`)}}
            //.text-primary.text-weight-medium.line-height-20.q-mt-xs.text-ellipsis
            //    | {{ data.subtitleShort }}
            .text-grey-9.text-weight-regular.line-height-20.text-ellipsis.font-14
                | {{data.manufacture}}
            div(v-if="notAvailable").q-mb-sm.q-pa-none.q-mt-sm.q-pr-lg
                p.q-ma-none.text-weight-medium.text-grey-8 нет в наличии
            .row.q-mt-xs.justify-between.items-end.no-wrap(v-else style="min-height: 40px")
                //.text-positive.text-weight-medium.line-height-20
                //    | {{ data.price }} ₽
                .row.items-center.no-wrap
                    .column.text-positive.text-weight-medium.line-height-20
                        template(v-if="data.isMarker")
                            .row.no-wrap.items-center(v-if="data.priceCrossed && data.priceCrossed !== data.price")
                                span.font-12.card__price-new.text-grey-9.text-weight-medium(
                                    v-if="(data.priceCrossed !== data.price) && data.priceCrossed"
                                    style="text-decoration: line-through;")
                                    span(v-if="!userAddress?.address_id" ) от&nbsp;
                                    | {{ new Intl.NumberFormat('ru-RU').format(data.priceCrossed) }}
                                    span &nbsp₽
                                .sale.q-ml-xs.b-radius-16.outline-negative.flex.items-center(v-if="salePercent > 0" style="padding: 2px 4px")
                                    .font-12.line-height-16.text-negative.text-weight-medium
                                        | {{ salePercent }}%
                            span.text-weight-bold.font-14.card__price-old
                                span(v-if="!userAddress?.address_id" ) от&nbsp;
                                | {{ new Intl.NumberFormat('ru-RU').format(data.price) }}
                                span &nbsp₽
                        template(v-else)
                            span.text-weight-bold.font-14.card__price-old(v-if="!!data.price" )
                                span(v-if="!userAddress?.address_id" ) от&nbsp;
                                |  {{ new Intl.NumberFormat('ru-RU').format(data.price) }}
                                span &nbsp₽


                .row.justify-end.product__counter.no-wrap(v-if="data.inBasket || inCart ")
                    q-btn.justify-center.items-center.product__button.product__button-min.cursor-pointer.font-16.text-positive(
                        unelevated
                        rounded
                        @click.stop.prevent="setAmount(0)") &ndash;
                    .row.justify-center.items-center.product__count.font-16.text-weight-medium.text-positive(v-if="!loading" ) {{ amount }}
                    .row.justify-center.items-center.product__count.font-16.text-weight-medium.text-positive(v-if="loading" )
                        q-spinner(
                            :thickness="5"
                            :color="'positive'")
                    q-btn.justify-center.items-center.product__button.product__button-plus.cursor-pointer.font-16.text-positive(
                        v-if="!preventAmoutUp"
                        unelevated
                        rounded
                        @click.stop.prevent="setAmount(1)") +
                    q-btn.justify-center.items-center.product__button.product__button__disable.cursor-pointer.font-16(
                        v-else
                        unelevated
                        rounded
                        @click.stop.prevent="") +
                        base-tooltip(
                            style="max-width: 300px;"
                            title="Количество ограничено")
                base-btn(
                    v-else
                    width="90"
                    height="24"
                    color="positive"
                    label="В корзину"
                    :loading="loading"
                    :disable="loading"
                    loading_color="secondary"
                    font_size="12"
                    noPadding
                    @click.prevent.stop="addToCart"
                )
                base-btn(
                    v-if="basket && data.inBasket"
                    width="90"
                    height="24"
                    color="grey-9"
                    label="В корзине"
                    font_size="12"
                    noPadding
                    @click.prevent.stop=""
                )
</template>

<script>

import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useQuasar } from 'quasar';
import { useRouter } from 'vue-router';

import BaseBtn from '../Base/BaseBtn.vue';

export default {
    components: {
        BaseBtn,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        basket: {
            type: Boolean,
            default: () => true,
        },
        variant: {
            type: String,
        },
        pickupVariant: {
            type: String,
        },
        productCardPreview: {
            type: Boolean,
            default: () => false,
        },
        viewCart: {
            type: Boolean,
            default: () => false,
        },
        sliderProductDay: {
            type: Boolean,
            default: () => false,
        },
    },
    setup(props, { emit }) {
        const store = useStore();
        const $q = useQuasar();

        const amount = ref(1);
        const loading = ref(false);
        const inCart = ref(false);
        const isLocalFavourite = ref(false);
        const amountTimer = ref(null);
        const router = useRouter();

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const reorderProduct = async (product) => {
            if (props.basket) {
                emit('reorderProduct', product);
                loading.value = true;
                const in_basket = false;

                try {
                    await store.dispatch('basketNew/PUT_PRODUCT_IN_BUSKET', {
                        product: {
                            id: props.data.id,
                            amount: 1,
                            checked: true,
                        },
                        in_basket,
                    });

                    if (props.basket && props.variant == 'pickup') {
                        await store.dispatch('basketNew/GET_PICKUP_BASKET', props.pickupVariant);
                    }
                    if (props.basket && props.variant == 'courier') {
                        await store.dispatch('basketNew/GET_DELIVERY_BASKET', props.pickupVariant);
                    }
                    loading.value = false;
                } catch (error) {
                    loading.value = false;
                    $q.notify({
                        color: 'negative',
                        message: 'Произошла ошибка. Повторите попытку позже',
                    });
                }
                ;
            } else {
                emit('reorderProduct', product);
            }
        };

        const userAuthorized = computed(() => store.getters['user/USER_AUTHORIZED']);
        const cartProducts = computed(() => store.getters['localStorage/BASKET_PRODUCTS']);
        const userAddress = computed(() => store.getters['user/USER_ADDRESS']);

        const isFavourite = computed(() => {
            if (userAuthorized.value) {
                return isLocalFavourite.value;
            } else {
                const favourites = store.getters['localStorage/FAVOURITES'];
                return favourites?.find((item) => item === props.data.id);
            }
        });

        const updateFavourite = async () => {
            window?.TapticEngine?.unofficial.weakBoom();
            if (isFavourite.value) {
                userAuthorized.value
                    ? deleteProductFromUserFav()
                    : store.commit('localStorage/DELETE_FAVOURITE_PRODUCT', props.data.id);
            } else {
                userAuthorized.value
                    ? addProductToUserFav()
                    : store.commit('localStorage/ADD_FAVOURITE_PRODUCT', props.data.id);
            }
            if (props.viewCart) {
                await store.dispatch('basketNew/GET_BASKET');
            }
        };

        const deleteProductFromUserFav = async () => {
            try {
                await store.dispatch('user/DELETE_FAVOURITE_PRODUCT', props.data.id);
                await store.commit('user/DELETE_USER_FAVOURITE_PRODUCT', props.data.id);
                isLocalFavourite.value = false;
            } catch (e) {
                isLocalFavourite.value = true;
            }
        };

        const addProductToUserFav = async () => {
            try {
                await store.dispatch('user/ADD_FAVOURITE_PRODUCT', props.data.id);
                isLocalFavourite.value = true;
            } catch (e) {
                isLocalFavourite.value = false;
            }
        };
        const formattedName = (text) => {
            if (!text) return '';

            const lines = text.split(' ').reduce((acc, word) => {
                const lastLine = acc[acc.length - 1];
                if (lastLine && lastLine.length + word.length + 1 > 45) {
                    acc.push(word);
                } else {
                    if (lastLine) {
                        acc[acc.length - 1] += ' ' + word;
                    } else {
                        acc.push(word);
                    }
                }
                return acc;
            }, []);

            if (lines.length > 2) {
                lines.length = 2;
                lines[1] += '...';
            }

            return lines.join(' ');
        };

        const notAvailable = computed(() => {
            return !!(!props.data.priceNew && !props.data.priceOld && !props.data.minPrice)
                || !!(!props.data.maxPreorderCount && !props.data.maxStockCount);
        });
        const preventAmoutUp = computed(() => {
            if (props.data.limit < 0) {
                return amount.value >= props.data.totalCount;
            } else if (props.data.limit == 0 || props.data.pku) {
                return false;
            } else if (props.data.limit > 0) {
                return amount.value >= props.data.limit;
            }
            return false;
        });
        //скидка
        const salePercent = computed(() => {
            if (props.data.priceCrossed) {
                const percent = ((props.data.priceCrossed - props.data.price) / props.data.priceCrossed) * 100;
                const decimalPart = Math.floor((percent % 1) * 10);

                if (decimalPart > 5) {
                    return `${Math.ceil(percent)}`;
                } else {
                    return `${Math.floor(percent)}`;
                }
            } else {
                return false;
            }
        });
        const addToCart = async () => {
            store.commit('basketNew/SET_BASKET_LOADING', true);

            userAuthorized.value
                ? await putProductInCart()
                : await putProductInLocalCart();

            try {
                if (props.viewCart) {
                    await store.dispatch('basketNew/GET_BASKET');
                }
                if (process.env.CLIENT) {
                    ym(72234883, 'reachGoal', 'klik-korzina');
                    window.dataLayer.push({
                        'ecommerce': {
                            'currencyCode': 'RUB',
                            'add': {
                                'products': [
                                    {
                                        'id': props.data.id,
                                        'name': props.data.name + props.data.subtitleShort,
                                        'brand': props.data.manufacture,
                                        'price': props.data.price,
                                    },
                                ],
                            },
                        },
                    });
                }


            } catch (err) {
                console.log(err);
            }

            store.commit('basketNew/SET_BASKET_LOADING', false);
        };
        // добавление товара в корзину для авторизованных
        const putProductInCart = async () => {
            loading.value = true;
            const in_basket = false;

            if (amount.value && amount.value > 0) {
                try {
                    await store.dispatch('basketNew/PUT_PRODUCT_IN_BUSKET', {
                        product: {
                            id: props.data.id,
                            amount: amount.value,
                            checked: true,
                        },
                        in_basket,
                    });

                } catch (error) {
                    $q.notify({
                        color: 'negative',
                        message: 'Произошла ошибка. Повторите попытку позже',
                    });
                }
            }

            if (amount.value == 1 && props.recommendationId && !inCart.value) {
                emit('cleanRecom');
                await store.dispatch('basketNew/CHANGE_BASKET_PRODUCT_AMOUNT', {
                    id: props.recommendationId,
                    count: 0,
                    variant: props.variant,
                    date: props.pickupVariant,
                });
            }

            inCart.value = true;

            loading.value = false;
        };

        const putProductInLocalCart = async (id = props.data.url) => {
            loading.value = true;

            const payload = await store.dispatch('products/GET_PRODUCT_BY_SLUG', { id });

            store.commit('localStorage/ADD_TO_CART', payload);

            inCart.value = true;

            loading.value = false;
        };

        // удаление товара из корзины для авторизоаванных
        const delProductInCart = async () => {
            const in_basket = false;

            try {
                //await store.dispatch('basket/BASKET_DEL_PRODUCT', { id: props.data.id, in_basket });
                await store.dispatch('basketNew/CHANGE_BASKET_PRODUCT_AMOUNT', {
                    id: props.data.id,
                    count: 0,
                });
                inCart.value = false;
                if (props.viewCart) {
                    store.commit('basketNew/SET_BASKET_LOADING', true);
                    await store.dispatch('basketNew/GET_BASKET');
                    store.commit('basketNew/SET_BASKET_LOADING', false);
                }
                try {
                    window.dataLayer.push({
                        'ecommerce': {
                            'currencyCode': 'RUB',
                            'remove': {
                                'products': [
                                    {
                                        'id': props.data.id,
                                        'name': props.data.name + props.data.subtitleShort,
                                        'brand': props.data.manufacture,
                                        'price': props.data.price,
                                    },
                                ],
                            },
                        },
                    });
                } catch {
                }
            } catch (error) {
                $q.notify({
                    color: 'negative',
                    message: error.message,
                });
            }
        };

        const checkLocalBasket = () => {
            let localProd = cartProducts.value?.find((item) => item.id === props.data.id);

            if (localProd) {
                amount.value = localProd.amount;
                inCart.value = true;
            } else {
                amount.value = 1;
                inCart.value = false;
            }
        };

        const checkProdInBasket = () => {
            if (props.data.inBasket) {
                amount.value = props.data.inBasket;
                inCart.value = true;
            } else {
                amount.value = 1;
                inCart.value = false;
            }
        };

        const setAmount = async (pm) => {
            loading.value = true;
            store.commit('basketNew/SET_BASKET_LOADING', true);
            if (pm) {
                amount.value++;
            } else {
                if (!(amount.value - 1) || amount.value - 1 < 0) {
                    userAuthorized.value
                        ? await delProductInCart()
                        : store.commit('localStorage/DELETE_ITEMS', props.data.id);
                    amount.value = 1;
                    inCart.value = false;
                    if (props.viewCart) {
                        await store.dispatch('basketNew/GET_BASKET');
                    }
                    loading.value = false;
                    store.commit('basketNew/SET_BASKET_LOADING', false);
                    return;
                } else {
                    amount.value--;
                    if (props.viewCart) {
                        await store.dispatch('basketNew/GET_BASKET');
                    }
                }
            }

            if (userAuthorized.value) {
                /**
                 * Если пользователь авторизован
                 * ставим задержку на отправку данных о количестве товара в корзине в 1 сек.
                 */

                if (amountTimer.value) {
                    clearTimeout(amountTimer.value);
                }
                amountTimer.value = setTimeout(async () => {
                    loading.value = true;
                    await putProductInCart();
                }, 300);
            } else {
                // если пользователь НЕ авторизован
                // сохраняем кол-во товара локально в браузере
                store.commit('localStorage/SET_AMOUNT', {
                    id: props.data.id,
                    amount: amount.value,
                });

            }
            if (props.viewCart) {
                await store.dispatch('basketNew/CHANGE_BASKET_PRODUCT_AMOUNT', {
                    id: props.data.id,
                    count: amount.value,
                });
                await store.dispatch('basketNew/GET_BASKET');
            }
            store.commit('basketNew/SET_BASKET_LOADING', false);
            loading.value = false;
        };
        const goTo = () => {
            router.push(city.value ? `/${city.value.slug}/product/${props.data.url}` : `/product/${props.data.url}`);
            //if (process.env.CLIENT) window.open(city.value ? `${city.value.slug}/product/${props.data.url}` : `/product/${props.data.url}`, '_blank');
        };
        watch(() => props.data.inBasket, () => {
            if (props.data.inBasket) {
                amount.value = props.data.inBasket;
                inCart.value = true;
            } else {
                amount.value = 1;
                inCart.value = false;
            }
        });


        onMounted(() => {
            if (props.data.isFavourite)
                isLocalFavourite.value = true;
            if (!userAuthorized.value) {
                checkLocalBasket();
            } else {
                checkProdInBasket();
            }
        });


        return {
            reorderProduct,
            updateFavourite,
            isLocalFavourite,
            isFavourite,
            formattedName,
            salePercent,
            setAmount,
            addToCart,
            notAvailable,
            amount,
            preventAmoutUp,
            checkLocalBasket,
            checkProdInBasket,
            inCart,
            loading,
            userAddress,
            goTo,
        };
    },
};
</script>

<style lang="scss" scoped>
.preview {
    width: 284px;
    height: 131px;
    overflow: hidden;
    text-decoration: none;

    .product__counter {
        border-radius: 20px;
        overflow: hidden;
        color: #242424;
        height: 28px;
        bottom: 1px;
        position: relative;
        font-size: 12px;
        font-weight: 500;
    }

    .product__button {
        width: 34px;
        background-color: #F2F2F2;
        text-transform: none;
        border-radius: 0;
        min-height: 28px;
        max-height: 30px;
        padding-top: 2px;
        font-weight: 600;

        font: {
            size: 16px;
            weight: 700;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .product__count {
        width: 34px;
    }

    span.block {
        &#name {
            display: block !important;
            height: 40px;
            overflow: hidden;
            white-space: pre-line;
            max-width: 158px;
            text-overflow: ellipsis;
            position: relative;
            line-height: 20px;
        }
    }
}


</style>
